export const SET_INCREMENT = "SET_INCREMENT";
export const SET_DECREMENT = "SET_DECREMENT";

// HEADER SCENE LIBRARY OPTION HINDE AND SHOW 
export const SHOW_SCENE_LIBRARY = "SHOW_SCENE_LIBRARY";
export const HIDE_SCENE_LIBRARY = "HIDE_SCENE_LIBRARY";

// FOOTER BUTTONS OPTION HIDE AND SHOW
export const SHOW_IMAGE_BUTTON_OPTIONS = "SHOW_IMAGE_BUTTON_OPTIONS";
export const HIDE_IMAGE_BUTTON_OPTIONS = "HIDE_IMAGE_BUTTON_OPTIONS";

// IMAGE OPTIONS TOGGLE STATE 
export const SHOW_ADD_POINT = "SHOW_ADD_POINT";
export const SHOW_ADD_MASK = "SHOW_ADD_MASK";
export const HIDE_ADD_POINT = "HIDE_ADD_POINT";
export const SHOW_ADD_TEST_POINT = "SHOW_ADD_TEST_POINT";
export const HIDE_ADD_TEST_POINT = "HIDE_ADD_TEST_POINT";
export const SHOW_EDIT_CAMERA_POINT = "SHOW_EDIT_CAMERA_POINT";
export const HIDE_EDIT_CAMERA_POINT = "HIDE_EDIT_CAMERA_POINT";
export const SHOW_MOVE_POINT = "SHOW_MOVE_POINT";
export const HIDE_MOVE_POINT = "HIDE_MOVE_POINT";
export const SHOW_VIEW_POINT_COORDINATES = "SHOW_VIEW_POINT_COORDINATES";
export const HIDE_VIEW_POINT_COORDINATES = "HIDE_VIEW_POINT_COORDINATES";
export const SHOW_SAVE_PROJECT = "SHOW_SAVE_PROJECT";
export const HIDE_SAVE_PROJECT = "HIDE_SAVE_PROJECT";
export const SHOW_VIEW_OPTIONS = "SHOW_VIEW_OPTIONS";
export const HIDE_VIEW_OPTIONS = "HIDE_VIEW_OPTIONS";
export const SHOW_EDIT_SCENE = "SHOW_EDIT_SCENE";
export const HIDE_EDIT_SCENE = "HIDE_EDIT_SCENE";
export const TOGGLE_All_CAMERAS = "TOGGLE_All_CAMERAS";
export const TOGGLE_INVERT_FLOORPLAN_COLORS = "TOGGLE_INVERT_FLOORPLAN_COLORS";
export const TOGGLE_POINT_COLORS = "TOGGLE_POINT_COLORS";
export const TOGGLE_PLOT_GRIDLINES = "TOGGLE_PLOT_GRIDLINES";
export const IMAGE_MAPPING_LOADING = "IMAGE_MAPPING_LOADING";
export const SCENE_MULTIPLE_FLAG = "SCENE_MULTIPLE_FLAG";
export const SHOW_INFO_POPUP = "SHOW_INFO_POPUP";
export const HIDE_INFO_POPUP = "HIDE_INFO_POPUP";
export const SHOW_ADD_SCENE_POPUP = "SHOW_ADD_SCENE_POPUP";
export const HIDE_ADD_SCENE_POPUP = "HIDE_ADD_SCENE_POPUP";
export const GENERATE_HOMOGRAPHY = "GENERATE_HOMOGRAPHY";
export const GENERATE_TPS = "GENERATE_TPS";
export const GENERATE_CUBIC = 'GENERATE_CUBIC'
export const GENERATE_CV2 = 'GENERATE_CV2'
export const SELECTED_SHAPE = "SELECTED_SHAPE";
export const SELECTED_SHAPE_TO_DELETE = "SELECTED_SHAPE_TO_DELETE";

// IMAGE DATA
// SET_CAMERA_POINTS AND SET_FLOOR_PLAN_POINTS ARE NEW STATES FOR STORING CAMERA
// AND FLOORPLAN POINTS. THESE NEW STATES ARE A PART OF THE REFACTOR 
export const SET_IMAGE_URLS = "SET_IMAGE_URLS";
export const SET_CAMERA_IMAGE_URLS = "SET_CAMERA_IMAGE_URLS";
export const SET_CREATE_PROJECT_DATA = "SET_CREATE_PROJECT_DATA";
export const SET_CURRENT_CAMERA_IMAGE_URL = "SET_CURRENT_CAMERA_IMAGE_URL";
export const SET_FLOOR_PLAN_MARKERS = "SET_FLOOR_PLAN_MARKERS";
export const SET_FLOOR_PLAN_POINTS = "SET_FLOOR_PLAN_POINTS";
export const SET_CAMERA_POINTS = "SET_CAMERA_POINTS";
export const SET_MASK_POINTS = "SET_MASK_POINTS";
export const SET_FLOOR_PLAN_TEST_POINT = "SET_FLOOR_PLAN_TEST_POINT";
export const SET_CAMERA_TEST_POINT = "SET_CAMERA_TEST_POINT";
export const ADD_FLOOR_PLAN_TEST_POINT = "ADD_FLOOR_PLAN_TEST_POINT";
export const ADD_CAMERA_TEST_POINT = "ADD_CAMERA_TEST_POINT";

export const SET_CAMERA_MARKERS = "SET_CAMERA_MARKERS";
export const SET_LOAD_CAMERA_URL = "SET_LOAD_CAMERA_URL";
export const ENABLE_ADJUST_VIEW_POSITION = "ENABLE_ADJUST_VIEW_POSITION";
export const ENABLE_CAMERA_ADJUST_VIEW_POSITION = "ENABLE_CAMERA_ADJUST_VIEW_POSITION";
export const SET_CAMERA_POSITION_MARKER = "SET_CAMERA_POSITION_MARKER";
export const SET_CURRENT_CAMERA_IMAGE_INDEX = "SET_CURRENT_CAMERA_IMAGE_INDEX";
export const SET_CURRENT_CAMERA_NOTES = "SET_CURRENT_CAMERA_NOTES";
export const SET_SELECTED_CAMERA_MARKER = "SET_SELECTED_CAMERA_MARKER";
export const SET_SELECTED_FLOOR_MARKER = "SET_SELECTED_FLOOR_MARKER";
export const SET_HOMOGRAPHY = "SET_HOMOGRAPHY";
export const ZOOM_SCALE = "ZOOM_SCALE";
export const CAMERA_ZOOM_SCALE = "CAMERA_ZOOM_SCALE";
export const FLOOR_VIEW_POSITION = "FLOOR_VIEW_POSITION";
export const CAMERA_VIEW_POSITION = "CAMERA_VIEW_POSITION";
export const ZOOM_OUT_TOGGLE = "ZOOM_OUT_TOGGLE";
export const ZOOM_IN_TOGGLE = "ZOOM_IN_TOGGLE";
export const ZOOM_CANVAS_ID = "ZOOM_CANVAS_ID";
export const SET_COUNT_HOMOGRAPHY_CALL = "SET_COUNT_HOMOGRAPHY_CALL";
export const SET_MASK_SHAPES = "SET_MASK_SHAPES";
export const SET_SELECTED_FLOOR_TEST_POINT = "SET_SELECTED_FLOOR_TEST_POINT";
export const SET_SELECTED_CAMERA_TEST_POINT = "SET_SELECTED_CAMERA_TEST_POINT";
export const STORE_POINTS = 'STORE_POINTS';
export const SET_FLOORPLAN_TEST_MARKER = 'SET_FLOORPLAN_TEST_MARKER';
export const SET_CAMERA_TEST_MARKER = 'SET_CAMERA_TEST_MARKER';
export const UPDATE_COORDINATES = 'UPDATE_COORDINATES';
export const SET_FLOOR_PLAN_BOUNDS = 'SET_FLOOR_PLAN_BOUNDS';
export const SET_CAMERA_BOUNDS = 'SET_CAMERA_BOUNDS';
export const SET_DELETE_POINT = 'SET_DELETE_POINT';
export const SET_FLOORPLAN_DIMENSIONS = 'SET_FLOORPLAN_DIMENSIONS'
export const SET_CAMERA_DIMENSIONS = 'SET_CAMERA_DIMENSIONS'
export const SET_IMAGE_FILE = 'SET_IMAGE_FILES'
export const SET_CAMERA_FILES = 'SET_CAMERA_FILES'
export const SET_IMAGE_UPLOADED='SET_IMAGE_UPLOADED'
export const SET_CAMERA_IMAGE_UPLOADED='SET_CAMERA_IMAGE_UPLOADED'
export const SET_CORRESPONDING_FLOORPLAN_POINT_ID = 'SET_CORRESPONDING_FLOORPLAN_POINT_ID'
export const SET_CORRESPONDING_CAMERA_POINT_ID = 'SET_CORRESPONDING_CAMERA_POINT_ID'
export const SET_CAMERA_POINTS_AVG = 'SET_CAMERA_POINTS_AVG'

// RESET
export const RESET_STATE = "RESET_STATE";