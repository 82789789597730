import { useSelector, useDispatch } from "react-redux";
import React from "react";
import {
  showAddPoint,
  showAddMask,
  showViewPointCoordinates,
  showEditCameraPoint,
  showMovePoint,
  showViewOptions,
  setGenerateTPS,
  showAddTestPoint,
  hideAddTestPoint,
  setGenerateHomography,
  hideAddPoint,
  setStorePoints,
  hideMovePoint,
  setDeletePoint,
  setGenerateCubic,
  setGenerateCv2,
} from "./../../actions/imageOptions";
import "./Footer.css";
import {
  setCameraAdjustViewPosition,
  setAdjustViewPosition,
  setloadCameraUrl,
  addFloorPlanTestPoint,
  setFloorPlanTestPoint,
  addCameraTestPoint,
  setCameraTestPoint,
} from "./../../actions/imageData";
import GenerateHomography from "../GenerateHomography/GenerateHomography";
import GenerateProjection from "../Popups/GenerateProjection/GenerateProjection";
import { useState, useEffect } from "react";
import Loader from "./../helpers/loader/Loader";

function Footer() {
  const [homographyPopup, setHomographyPopup] = useState(false);
  const btnToggle = useSelector((state) => state.imageBtnOptions);
  const cameraPostionMarker = useSelector(
    (state) => state.imageDataReducer.cameraPositionMarker
  );
  const toggle = useSelector((state) => state.imageOptionsReducer);
  const generate = useSelector(
    (state) => state.imageOptionsReducer.generateHomography
  );
  const generate_tps = useSelector(
    (state) => state.imageOptionsReducer.generateTPS
  );
  const generate_cubic = useSelector(
    (state) => state.imageOptionsReducer.generateCubic
  );
  const generate_cv2 = useSelector(
    (state) => state.imageOptionsReducer.generateCv2
  );
  const addTestPointToggle = useSelector(
    (state) => state.imageOptionsReducer.addTestPointToggle
  );
  const addMaskToggle = useSelector(
    (state) => state.imageOptionsReducer.addMaskToggle
  );
  const homographyData = useSelector(
    (state) => state.imageDataReducer.homography
  );
  const currentCameraIndex = useSelector(
    (state) => state.imageDataReducer.currentCameraIndex
  );
  const floorPlanAPITestPoints = useSelector(
    (state) => state.imageDataReducer.floorPlanAPITestPoints
  );
  const cameraAPITestPoints = useSelector(
    (state) => state.imageDataReducer.cameraAPITestPoints
  );
  const floorPlanTestPoints = useSelector(
    (state) => state.imageDataReducer.floorPlanTestPoints
  );
  const cameraTestPoints = useSelector(
    (state) => state.imageDataReducer.cameraTestPoints
  );
  const imageMappingLoading = useSelector(
    (state) => state.imageOptionsReducer.imageMappingLoading
  );
  const floorplanmarkers = useSelector(
    (state) => state.imageDataReducer.floorplanmarkers
  );
  const cameramarkers = useSelector(
    (state) => state.imageDataReducer.cameramarkers
  );
  const deletePoint = useSelector(
    (state) => state.imageOptionsReducer.deletePoint
  );
  const [btnClick, setBtnClick] = useState(false);
  const dispatch = useDispatch();

  let waitingForCameraLoop =
    cameramarkers && cameramarkers[currentCameraIndex] !== undefined
      ? cameramarkers[currentCameraIndex].length
      : 0;
  let waitingForFloorLoop =
    floorplanmarkers && floorplanmarkers[currentCameraIndex] !== undefined
      ? floorplanmarkers[currentCameraIndex].length
      : 0;
  useEffect(() => {
    let Footer = document.querySelector(".Footer");
    if (window.screen.width < 1300 && btnToggle) {
      Footer.style.height = "105px";
    } else {
      Footer.style.height = "65px";
    }
  });

  const addPointClick = () => {
    dispatch(showAddPoint());
    dispatch(setAdjustViewPosition(false));
    dispatch(setCameraAdjustViewPosition(false));
    dispatch(hideMovePoint());
    dispatch(setDeletePoint(false));
  };

  const addMaskClick = () => {
    dispatch(showAddMask(!addMaskToggle));
    dispatch(hideAddTestPoint());
    dispatch(setAdjustViewPosition(false));
    dispatch(setCameraAdjustViewPosition(false));
    dispatch(hideMovePoint());
    dispatch(setDeletePoint(false));
  };

  const addTestPointClick = () => {
    if (!addTestPointToggle) {
      dispatch(showAddTestPoint());
    }
    dispatch(setCameraAdjustViewPosition(false));
    dispatch(setAdjustViewPosition(false));
    dispatch(hideMovePoint());
    dispatch(setDeletePoint(false));
  };

  const showHomography = () => {
    setBtnClick(true);
    if (waitingForCameraLoop !== 0 || waitingForFloorLoop !== 0) {
      return;
    }
    setHomographyPopup(true);
    dispatch(setAdjustViewPosition(false));

    if (
      floorPlanAPITestPoints &&
      cameraTestPoints &&
      floorPlanAPITestPoints[currentCameraIndex] &&
      cameraTestPoints[currentCameraIndex]
    ) {
      floorPlanAPITestPoints.splice(currentCameraIndex, 1, []);
      cameraTestPoints.splice(currentCameraIndex, 1, []);
      dispatch(addCameraTestPoint(cameraTestPoints));
      dispatch(setFloorPlanTestPoint(floorPlanAPITestPoints));
    }

    if (
      cameraAPITestPoints &&
      floorPlanTestPoints &&
      cameraAPITestPoints[currentCameraIndex] &&
      floorPlanTestPoints[currentCameraIndex]
    ) {
      cameraAPITestPoints.splice(currentCameraIndex, 1, []);
      floorPlanTestPoints.splice(currentCameraIndex, 1, []);
      dispatch(setCameraTestPoint(cameraAPITestPoints));
      dispatch(addFloorPlanTestPoint(floorPlanTestPoints));
    }
    dispatch(setloadCameraUrl(true));
    setBtnClick(false);
  };

  const generateAPI = () => {
    dispatch(setStorePoints(true));
    waitingForCameraLoop =
      cameramarkers && cameramarkers[currentCameraIndex] !== undefined
        ? cameramarkers[currentCameraIndex].length
        : 0;
    waitingForFloorLoop =
      floorplanmarkers && floorplanmarkers[currentCameraIndex] !== undefined
        ? floorplanmarkers[currentCameraIndex].length
        : 0;
    // alert("Some  Co-ordinates are empty. Please enter the valid values.");
    if (waitingForCameraLoop) {
      cameramarkers[currentCameraIndex].forEach((element) => {
        if (element.XPos === "") {
          element.XPos = 0;
        } else if (element.YPos === "") {
          element.YPos = 0;
        }
        waitingForCameraLoop--;
        if (waitingForCameraLoop === 0) {
          showHomography();
        }
      });
    }

    if (waitingForFloorLoop) {
      floorplanmarkers[currentCameraIndex].forEach((element) => {
        if (element.XPos === "") {
          element.XPos = 0;
        } else if (element.YPos === "") {
          element.YPos = 0;
        }
        waitingForFloorLoop--;
        if (waitingForFloorLoop === 0) {
          showHomography();
        }
      });
    }

    if (waitingForFloorLoop === 0 && waitingForCameraLoop === 0) {
      showHomography();
    }
  };

  const selectandMove = () => {
    dispatch(showMovePoint());
    dispatch(setDeletePoint(false));
  };

  const toggleDeletePoint = () => {
    dispatch(setDeletePoint(!deletePoint));
    dispatch(hideAddTestPoint());
    dispatch(hideAddPoint());
    dispatch(hideMovePoint());
  };

  return (
    <div className="Footer">
      <Loader show={btnClick || imageMappingLoading} />
      {generate && (
        <GenerateHomography
          show={generate}
          onHide={() => {
            dispatch(setGenerateHomography(false));
            dispatch(setStorePoints(false));
          }}
        />
      )}
      {generate_tps && (
        <GenerateHomography
          show={generate_tps}
          onHide={() => {
            dispatch(setGenerateTPS(false));
            dispatch(setStorePoints(false));
          }}
        />
      )}
      {generate_cubic && (
        <GenerateHomography
          show={generate_cubic}
          onHide={() => {
            dispatch(setGenerateCubic(false));
            dispatch(setStorePoints(false));
          }}
        />
      )}
      {generate_cv2 && (
        <GenerateHomography
          show={generate_cv2}
          onHide={() => {
            dispatch(setGenerateCv2(false));
            dispatch(setStorePoints(false));
          }}
        />
      )}
      {btnToggle && (
        <GenerateProjection
          show={homographyPopup}
          onHide={() => setHomographyPopup(false)}
        />
      )}
      {btnToggle && (
        <div className="toolbuttongroup">
          <button
            className={`toolbutton addPoint ${
              toggle.addPointToggle ? "activeTool" : ""
            }`}
            onClick={addPointClick}
          >
            Add Point
          </button>
          <button
            className={`toolbutton testPoint ${
              toggle.addTestPointToggle ? "activeTool" : ""
            }`}
            onClick={addTestPointClick}
          >
            Add Test Points
          </button>
          <button
            className={`toolbutton editPoint ${
              toggle.selectAndMovePointToggle ? "activeTool" : ""
            }`}
            onClick={selectandMove}
          >
            Select &amp; Move Point
          </button>
          <button
            className={`toolbutton deletePoint ${
              toggle.deletePoint ? "activeTool" : ""
            }`}
            onClick={toggleDeletePoint}
          >
            Delete Point
          </button>

          <button
            className={`toolbutton pointCoordinates ${
              toggle.viewPointCoordinatesToggle ? "activeTool" : ""
            }`}
            onClick={() => dispatch(showViewPointCoordinates())}
          >
            View Point Coordinates
          </button>
          <button
            className="viewbutton viewOption"
            onClick={() => dispatch(showViewOptions())}
          >
            View Options
          </button>
          <button
            className={`toolbutton addCamera ${
              toggle.editCameraPostionToggle ? "activeTool" : ""
            }`}
            onClick={() => dispatch(showEditCameraPoint())}
          >
            {cameraPostionMarker.length > 0
              ? "Edit Camera Position"
              : "Add Camera Marker"}
          </button>
          <button
            className="viewbutton generateHomography"
            onClick={generateAPI}
          >
            {homographyData &&
            homographyData.length != 0 &&
            homographyData[currentCameraIndex] &&
            homographyData[currentCameraIndex].projection_type
              ? "Update Projection"
              : "Generate Projection"}
          </button>
          <button
            className={`toolbutton addPoint ${
              toggle.addMaskToggle ? "activeTool" : ""
            }`}
            onClick={addMaskClick}
          >
            Mask Toggle
          </button>
        </div>
      )}
    </div>
  );
}

export default Footer;
