import React, { useState } from "react";
import CustomReactPictureAnnotation from "../../ImageAnnotationComponent";
import floorPlanPointer from "../../../assets/images/markerPoint-floorPlan.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  setAdjustViewPosition,
  setloadCameraUrl,
  addFloorPlanTestPoint,
  setFloorPlanTestPoint,
  addCameraTestPoint,
  setCameraTestPoint,
} from "../../../actions/imageData";
import {
  hideAddPoint,
  hideAddTestPoint,
  hideMovePoint,
} from "../../../actions/imageOptions";
import * as Constants from "../../../constants";

export default function FloorPlanView() {
  const dispatch = useDispatch();
  const imageUrls = useSelector((state) => state.imageDataReducer.imageUrls);
  let currentCameraIndex = useSelector(
    (state) => state.imageDataReducer.currentCameraIndex
  );
  const homography = useSelector((state) => state.imageDataReducer.homography);
  const adjustViewPositionToggle = useSelector(
    (state) => state.imageOptionsReducer.adjustViewPositionToggle
  );
  const currentCameraNotes = useSelector(
    (state) => state.imageDataReducer.currentCameraNotes
  );
  let floorPlanAPITestPoints = useSelector(
    (state) => state.imageDataReducer.floorPlanAPITestPoints
  );
  let cameraAPITestPoints = useSelector(
    (state) => state.imageDataReducer.cameraAPITestPoints
  );
  let floorPlanTestPoints = useSelector(
    (state) => state.imageDataReducer.floorPlanTestPoints
  );
  let cameraTestPoints = useSelector(
    (state) => state.imageDataReducer.cameraTestPoints
  );
  const floorplanTestMarker = useSelector(
    (state) => state.imageDataReducer.floorplanTestMarker
  );
  const [fakeState, setFakeState] = useState(false);

  const setSelectedMarkers = () => {
    setFakeState(!fakeState);
    dispatch(setAdjustViewPosition(false));
  };

  const handleOnMouseMoveFloor = (e) => {
    const otherMouseCursor = document.querySelector("#cameraCursor");
    otherMouseCursor.style.display = "none";

    let mouseCursor = document.querySelector("#floorCursor");
    mouseCursor.style.display = "initial";
    mouseCursor.style.left = `${e.pageX}px`;
    mouseCursor.style.top = `${e.pageY}px`;
  };

  const handleOnMouseLeave = () => {
    const mouseCursor = document.querySelector(".cursor");
    mouseCursor.style.display = "none";
  };

  const adjustViewPosition = () => {
    dispatch(setAdjustViewPosition(!adjustViewPositionToggle));
    dispatch(hideAddPoint());
    dispatch(hideAddTestPoint());
    dispatch(hideMovePoint());
  };

  const setFloorPlanTestPointsData = (addedPoints, APIPoints) => {
    dispatch(addFloorPlanTestPoint(addedPoints));
    dispatch(setCameraTestPoint(APIPoints));
    dispatch(setloadCameraUrl(true));
    dispatch(setAdjustViewPosition(false));
  };

  const setCameraTestPointsData = (addedPoints, APIPoints) => {
    dispatch(addCameraTestPoint(addedPoints));
    dispatch(setFloorPlanTestPoint(APIPoints));
    dispatch(setloadCameraUrl(true));
    dispatch(setAdjustViewPosition(false));
  };

  const setSelectedTestMarkers = () => {
    setFakeState(!fakeState);
  };

  const addTestPointAPI = (testPoint, test_point_type, testMarkers) => {
    let projection_type =
      homography && homography[currentCameraIndex]
        ? homography[currentCameraIndex]["projection_type"]
        : "";
    var testPoint_API_URL = "";
    if (projection_type === "Homography") {
      testPoint_API_URL =
        Constants.REACT_APP_HOMOGRAPHY_SERVICE + "/testpoint/";
    } else if (projection_type === "TPS") {
      testPoint_API_URL =
        Constants.REACT_APP_HOMOGRAPHY_SERVICE + "/tps/testpoint/";
    }
    let data = {
      test_point: testPoint,
      test_point_type: test_point_type === "world" ? "camera" : "world",
      plotting_model: homography[currentCameraIndex]["plotting_model"],
    };

    fetch(testPoint_API_URL, {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((data) => {
        // FLOOR PLAN TEST POINTS
        if (cameraAPITestPoints && floorPlanTestPoints) {
          if (
            cameraAPITestPoints[currentCameraIndex] &&
            floorPlanTestPoints[currentCameraIndex]
          ) {
            if (test_point_type === "camera") {
              cameraAPITestPoints[currentCameraIndex].push(data);
              floorPlanTestPoints[currentCameraIndex].push(testMarkers);
              setFloorPlanTestPointsData(
                floorPlanTestPoints,
                cameraAPITestPoints
              );
            }
          } else {
            if (test_point_type === "camera") {
              let tempAPITestPoints = cameraAPITestPoints;
              tempAPITestPoints[currentCameraIndex] = [];
              tempAPITestPoints[currentCameraIndex][0] = data;

              let addedTempTestPoints = floorPlanTestPoints;
              addedTempTestPoints[currentCameraIndex] = [];
              addedTempTestPoints[currentCameraIndex][0] = testMarkers;
              setFloorPlanTestPointsData(
                addedTempTestPoints,
                tempAPITestPoints
              );
            }
          }
        } else {
          if (test_point_type === "camera") {
            let tempAPITestPoints = [];
            tempAPITestPoints[currentCameraIndex] = [];
            tempAPITestPoints[currentCameraIndex][0] = data;

            let addedTempTestPoints = [];
            addedTempTestPoints[currentCameraIndex] = [];
            addedTempTestPoints[currentCameraIndex][0] = testMarkers;
            setFloorPlanTestPointsData(addedTempTestPoints, tempAPITestPoints);
          }
        }

        // CAMERA TEST POINTS
        if (floorPlanAPITestPoints && cameraTestPoints) {
          if (
            floorPlanAPITestPoints[currentCameraIndex] &&
            cameraTestPoints[currentCameraIndex]
          ) {
            if (test_point_type === "world") {
              floorPlanAPITestPoints[currentCameraIndex].push(data);
              cameraTestPoints[currentCameraIndex].push(testMarkers);
              setCameraTestPointsData(cameraTestPoints, floorPlanAPITestPoints);
            }
          } else {
            if (test_point_type === "world") {
              let tempAPITestPoints = floorPlanAPITestPoints;
              tempAPITestPoints[currentCameraIndex] = [];
              tempAPITestPoints[currentCameraIndex][0] = data;

              let addedTempTestPoints = cameraTestPoints;
              addedTempTestPoints[currentCameraIndex] = [];
              addedTempTestPoints[currentCameraIndex][0] = testMarkers;
              setCameraTestPointsData(addedTempTestPoints, tempAPITestPoints);
            }
          }
        } else {
          if (test_point_type === "world") {
            let tempAPITestPoints = [];
            tempAPITestPoints[currentCameraIndex] = [];
            tempAPITestPoints[currentCameraIndex][0] = data;

            let addedTempTestPoints = [];
            addedTempTestPoints[currentCameraIndex] = [];
            addedTempTestPoints[currentCameraIndex][0] = testMarkers;
            setCameraTestPointsData(addedTempTestPoints, tempAPITestPoints);
          }
        }
      })
      .catch((error) => {
        // setError(error.message);
        console.log(error.message);
      });
  };

  const updateTestPointAPI = (
    testPoint,
    test_point_type,
    testMarkers,
    pointerIndex
  ) => {
    let projection_type =
      homography && homography[currentCameraIndex]
        ? homography[currentCameraIndex]["projection_type"]
        : "";
    var testPoint_API_URL = "";
    if (projection_type === "Homography") {
      testPoint_API_URL =
        Constants.REACT_APP_HOMOGRAPHY_SERVICE + "/testpoint/";
    } else if (projection_type === "TPS") {
      testPoint_API_URL =
        Constants.REACT_APP_HOMOGRAPHY_SERVICE + "/tps/testpoint/";
    }
    let data = {
      test_point: testPoint,
      test_point_type: test_point_type === "world" ? "camera" : "world",
      plotting_model: homography[currentCameraIndex]["plotting_model"],
    };

    fetch(testPoint_API_URL, {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        else return response.json();
      })
      .then((data) => {
        if (test_point_type === "camera") {
          cameraAPITestPoints[currentCameraIndex][pointerIndex] = data;
          floorPlanTestPoints[currentCameraIndex][pointerIndex] = testMarkers;
          setFloorPlanTestPointsData(floorPlanTestPoints, cameraAPITestPoints);
        } else {
          floorPlanAPITestPoints[currentCameraIndex][pointerIndex] = data;
          cameraTestPoints[currentCameraIndex][pointerIndex] = testMarkers;
          setCameraTestPointsData(cameraTestPoints, floorPlanAPITestPoints);
        }
      });
  };
  return (
    <div className="image-inner-box">
      <div className="image-inner-box-header">
        <h3>FLOOR PLAN</h3>
        <div className="viewbuttongroup">
          <button className="viewBTN position" onClick={adjustViewPosition}>
            Adjust View Position
          </button>
        </div>
      </div>
      {(currentCameraNotes || !currentCameraNotes) && (
        <div
          className="image-mapping-box floorPlanSection"
          onMouseMove={handleOnMouseMoveFloor}
          onMouseLeave={handleOnMouseLeave}
          // onMouseEnter={handleOnMouseEnterFloor}
        >
          {/* <img className="cursor" id="floorCursor" src={crossHair} /> */}
          {/* PASS THE IMAGE URL AS PROPS. HERE THE CurrentCameraUrl prop will contain the floorplan image url */}
          <CustomReactPictureAnnotation
            id="Canvas"
            floorPlan={true}
            markerImg={floorPlanPointer}
            currentCameraUrl={imageUrls}
            adjustViewPositionToggle={adjustViewPositionToggle}
            setSelectedMarkers={setSelectedMarkers}
            addTestPointAPI={addTestPointAPI}
            updateTestPointAPI={updateTestPointAPI}
            setSelectedTestMarkers={setSelectedTestMarkers}
            testMarker={floorplanTestMarker}
          />
        </div>
      )}
    </div>
  );
}
